import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div>&copy; 2019 Kevin Zembrodt</div>
      </footer>
    );
  }
}
