import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './images/code_hero.jpg';
import './index.css';
import './sanitize.css';
// Internet Explorer 6-11
// let isIE = /*@cc_on!@*/ false || !!document.documentMode;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
